import { NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { navigateTo } from '@presentation/shared/router';
import { Subject, takeUntil } from 'rxjs';
import {
  EmailOTPDataModel,
  EmailOTPVerifyModel,
  OTPModel,
  PhoneDataModel,
} from 'src/app/core/domain/auth/auth.model';
import numberInputTypeMaxLengthValidator from 'src/app/presentation/profile/shared/validators/number-input-type-max-length.validator';
import { MetaPhoneNumberOutput } from 'src/app/presentation/shared/components/meta-phone-number-field/meta-phone-number-field.component';
// Ignoring the linting check because this comes from a Kotlin Library
import { user } from '@features/user/data';
import cache from '@mongez/cache';
import { parseError } from '@presentation/shared/error';
// @ts-ignore
import { getSupportedPhoneCountriesUseCase } from '@taager-experience-shared/country-resolver';
import { OTPVerificationModes } from 'app/core/domain/payment/intl-bank-transfer.model';
import { RequestEmailOTPUseCase } from 'app/core/usecases/auth/request-email-otp.usecase';
import { VerifyEmailOTPUseCase } from 'app/core/usecases/auth/verify-email.usecase';
import { GetFeatureAttributeUsecase } from 'app/core/usecases/get-feature-attribute.usecase';
import { EMAIL_OTP_WITHDRAW } from 'app/presentation/shared/constants/feature-flags';
import { featureAttributeAssign } from 'app/presentation/shared/utilities/feature-attribute-assign.utility';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { RequestOTPUseCase } from 'src/app/core/usecases/auth/request-otp.usecase';
import { SetRegisterTokenUseCase } from 'src/app/core/usecases/auth/set-register-token.usecase';
import { SetUserReferralUseCase } from 'src/app/core/usecases/auth/set-user-referral.usecase';
import { VerifyPhoneNumberUseCase } from 'src/app/core/usecases/auth/verify-phone.usecase';
import { Scope } from 'src/app/presentation/shared/components/shared-stepper-indicator/interfaces';
import {
  AUTH_CONSTS,
  PRODUCTS_V2_URL,
  SKIP_EMAIL_OTP,
} from 'src/app/presentation/shared/constants';
import { LocalStorageService } from 'src/app/presentation/shared/services/local-storage.service';
import { MetaPhoneNumberFieldComponent } from '../../../../shared/components/meta-phone-number-field/meta-phone-number-field.component';
import { CountdownTimerPipe } from '../../../../shared/pipes/countdown-timer.pipe';
import {
  SharedNotificationComponent,
  SharedNotificationConfig,
} from '../../../shared/notification/shared.notification.component';
import { SignupStepsBaseComponent } from '../signup-steps-base.component';
import { ConfirmSkipOtpDialogComponent } from './confirm-skip-otp-dialog/confirm-skip-otp-dialog.component';

const emailOtpResendExpireTimeCacheKey = 'rgemtp'; // register email otp resend expire time
const phoneOtpResendExpireTimeCacheKey = 'rgpmtp'; // register phone otp resend expire time
const emailOtpRequestObjectCacheKey = 'emailOTD'; // email otp request object
const phoneOTPCheckCodeCacheKey = 'rgpcc'; // register phone check code

@Component({
  styleUrls: ['signup-otp.component.scss'],
  templateUrl: 'signup-otp.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    SharedNotificationComponent,
    NgTemplateOutlet,
    MetaPhoneNumberFieldComponent,
    NgFor,
    NgClass,
    CountdownTimerPipe,
    MatDialogModule,
  ],
})
export class SignupOTPComponent
  extends SignupStepsBaseComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() scope: Scope;

  @Input() isUserLoggingIn: boolean;

  @ViewChild('successResponseTemplate')
  private _successResponseTemplate: TemplateRef<any>;

  @ViewChild('errorResponseTemplate')
  private _errorResponseTemplate: TemplateRef<any>;

  public responseMessage?: SharedNotificationConfig;

  public showEditPhoneNumber: boolean;

  public showEmailOTPonWithdrawal: boolean;

  public selectedOTPType: OTPVerificationModes | null = null;

  public signUpV2Enabled = true;

  emailOTPForm: UntypedFormGroup;

  emailFormInput = ['input1', 'input2', 'input3', 'input4'];

  phoneFormInput = ['input1', 'input2', 'input3', 'input4'];

  @ViewChildren('formRow') rows: any;

  public OTPFormGroup: UntypedFormGroup = new UntypedFormGroup({
    phoneNumber: new UntypedFormControl(''),
    phoneCountryCode: new UntypedFormControl(''),
    firstNumber: new UntypedFormControl('', [
      Validators.required,
      numberInputTypeMaxLengthValidator(1),
    ]),
    secondNumber: new UntypedFormControl('', [
      Validators.required,
      numberInputTypeMaxLengthValidator(1),
    ]),
    thirdNumber: new UntypedFormControl('', [
      Validators.required,
      numberInputTypeMaxLengthValidator(1),
    ]),
    fourthNumber: new UntypedFormControl('', [
      Validators.required,
      numberInputTypeMaxLengthValidator(1),
    ]),
  });

  private _expiryTimeInMilliSeconds: number;

  public currentTimeToExpiryInMilliSeconds: number;

  public currentEmailTimeExpiry: number;

  private _OTPExpiryIntervalRef: any;

  private _OTPSetTimeoutWrapper: any;

  public userPhoneNumber: string;

  public userEmail: string;

  public showOTPCodeTimer = false;

  public showEmailOTPCodeTimer = false;

  public otpEmailRequestObject: EmailOTPDataModel = cache.get(emailOtpRequestObjectCacheKey);

  private _onDestroy$: Subject<boolean> = new Subject<boolean>();

  public userCountryCode: string;

  public phonePrefix: string;

  public otpCheckCode? = cache.get(phoneOTPCheckCodeCacheKey);

  public otpVerificationOptions: {
    icon: string;
    title: string;
    text: string;
    value: string;
    isVerified: boolean;
    isDisabled: boolean;
    isSent: boolean;
  }[];

  private _tabbableOTPFields: { [fieldName: string]: ElementRef<HTMLInputElement> };

  private _EmailOTPExpiryIntervalRef: any;

  private _EmailOTPSetTimeoutWrapper: any;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _requestOTPUseCase: RequestOTPUseCase,
    private _verifyPhoneNumberUseCase: VerifyPhoneNumberUseCase,
    private _setRegisterTokenUseCase: SetRegisterTokenUseCase,
    private _setUserReferralUseCase: SetUserReferralUseCase,
    private _localStorageService: LocalStorageService,
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
    private _getFeatureAttributeUseCase: GetFeatureAttributeUsecase,
    private _requestEmailOTPUseCase: RequestEmailOTPUseCase,
    private _verifyEmailOTPUseCase: VerifyEmailOTPUseCase,
    private _matDialog: MatDialog,
  ) {
    super();
    this.emailOTPForm = this._toFormGroup(this.emailFormInput);
    this.OTPFormGroup = this._toFormGroup(this.phoneFormInput);
  }

  ngOnInit(): void {
    this._resolveCurrentUseCase();

    if (!user.isEmailVerified) {
      this.selectedOTPType = 'email';
    } else {
      this.selectedOTPType = 'sms';
      this._getUserPhoneData();
    }
  }

  private _shouldShowEmailOTPOnWithdrawal(): void {
    this._getFeatureAttributeUseCase.execute(EMAIL_OTP_WITHDRAW).subscribe({
      next: (attribute) => {
        this.showEmailOTPonWithdrawal =
          featureAttributeAssign(attribute, user.id) && this.scope === 'opt-in';
        if (this.showEmailOTPonWithdrawal) {
          this._initializeOTPOptions();
          this._getUserEmail();
          this._checkIfOTPVerified();
        } else {
          this._resolveOPTInScope();
        }
      },
    });
  }

  private _toFormGroup(elements: any): UntypedFormGroup {
    const group: any = {};

    elements.forEach((key: any) => {
      group[key] = new UntypedFormControl('', Validators.required);
    });
    return new UntypedFormGroup(group);
  }

  private _initializeOTPOptions(): void {
    const emailOtpExpireTime = cache.get(emailOtpResendExpireTimeCacheKey);

    let isEmailOtpSent = Boolean(emailOtpExpireTime);
    let isPhoneOtpSent = Boolean(cache.get(phoneOtpResendExpireTimeCacheKey));

    if (emailOtpExpireTime && emailOtpExpireTime < Date.now()) {
      cache.remove(emailOtpResendExpireTimeCacheKey);
      isEmailOtpSent = false;
    }

    const phoneOTPexpireTime = cache.get(phoneOtpResendExpireTimeCacheKey);

    if (phoneOTPexpireTime && phoneOTPexpireTime < Date.now()) {
      cache.remove(phoneOtpResendExpireTimeCacheKey);
      isPhoneOtpSent = false;
    }

    this.otpVerificationOptions = [
      {
        icon: 'assets/img/email-otp.svg',
        title: 'توثيق البريد الالكتروني',
        text: 'يضمن لك توثيق البريد الالكتروني خدمة دعم أفضل',
        value: 'email',
        isVerified: user.isEmailVerified,
        isSent: isEmailOtpSent,
        isDisabled: false,
      },
      {
        icon: 'assets/img/sms-otp.svg',
        title: 'توثيق رقم الهاتف',
        text: 'يساعدك توثيق الحساب برقم الهاتف على سحب أرباحك بأمان',
        value: 'sms',
        isVerified: false,
        isSent: isPhoneOtpSent,
        isDisabled: true, // not used yet
      },
    ];

    if (emailOtpExpireTime) {
      this._initializeEmailCounter(emailOtpExpireTime - Date.now());
    }
  }

  public get emailOtpIsSent(): boolean {
    return this.otpVerificationOptions[0].isSent;
  }

  public get phoneOtpIsSent(): boolean {
    return this.otpVerificationOptions[1].isSent;
  }

  public requestEmailOTP(): void {
    this.otpVerificationOptions[0].isSent = true;
    this._requestEmailOTPUseCase.execute('login').subscribe({
      next: (otpRequest) => {
        const timeInMilliSeconds = otpRequest.codeExpiryInMinutes * 60000;
        cache.set(emailOtpResendExpireTimeCacheKey, Date.now() + timeInMilliSeconds);

        this.otpEmailRequestObject = otpRequest;
        cache.set(emailOtpRequestObjectCacheKey, otpRequest);
        this._initializeEmailCounter(timeInMilliSeconds);
        this._commonChangeDetectorRef();
        this._trackOTPEvents('email_otp_requested', { response: otpRequest });
      },
      error: (err) => {
        const errorCode = err.error?.errorCode;
        const isEmailVerified = errorCode === 'email-already-verified';

        if (isEmailVerified) {
          this.otpTypeExpand('sms');
        } else {
          this.responseMessage = {
            msg: AUTH_CONSTS.REGISTER_ERRORS_MAP.get(err.error?.errorCode) || parseError(err),
            status: 'error',
            iconMeta: {
              icon: 'assets/img/auth/danger-white.svg',
              position: 'before',
            },
          };
          this._trackOTPEvents('email_otp_request_error', { error: err });
        }

        this._commonChangeDetectorRef();
      },
    });
  }

  private _trackOTPEvents(eventName: string, payload?: any): void {
    this._logMixpanelEventUseCase.execute({
      eventName,
      payload: {
        ...payload,
        scope: this.scope,
        type: 'verification',
      },
    });
  }

  private _initializeEmailCounter(expiryTimeInMilliSeconds: number): void {
    this._clearEmailOTPExpiryIntervalAndTimeout();
    this.showEmailOTPCodeTimer = true;
    this.currentEmailTimeExpiry = expiryTimeInMilliSeconds;
    const timeReductionInMilliSeconds = 1000;
    this._EmailOTPExpiryIntervalRef = setInterval(() => {
      if (this.currentEmailTimeExpiry <= 0) {
        clearInterval(this._EmailOTPExpiryIntervalRef);
        cache.remove(emailOtpResendExpireTimeCacheKey);
        return;
      }

      this.currentEmailTimeExpiry -= timeReductionInMilliSeconds;

      const expireTimeFromCache = cache.get(emailOtpResendExpireTimeCacheKey);

      cache.set(
        emailOtpResendExpireTimeCacheKey,
        expireTimeFromCache - timeReductionInMilliSeconds,
      );

      this._commonChangeDetectorRef();
    }, timeReductionInMilliSeconds);

    this._EmailOTPSetTimeoutWrapper = setTimeout(() => {
      this._clearEmailOTPExpiryIntervalAndTimeout();
      this.showEmailOTPCodeTimer = false;
      this._commonChangeDetectorRef();
    }, expiryTimeInMilliSeconds);
  }

  private _clearEmailOTPExpiryIntervalAndTimeout(): void {
    if (this._EmailOTPExpiryIntervalRef) {
      clearInterval(this._EmailOTPExpiryIntervalRef);
    }
    if (this._EmailOTPSetTimeoutWrapper) {
      clearTimeout(this._EmailOTPSetTimeoutWrapper);
    }
  }

  private _resolveCurrentUseCase(): void {
    switch (this.scope) {
      case 'opt-in':
        this._logMixpanelEventUseCase.execute({ eventName: 'opt-in_otp_page_load' });
        this._shouldShowEmailOTPOnWithdrawal();
        break;
      default:
        this._logMixpanelEventUseCase.execute({ eventName: 'sign-up_otp_page_load' });
        this._shouldShowNewSignUpFlow();
    }
  }

  private _shouldShowNewSignUpFlow(): void {
    this._initializeOTPOptions();
    this._getUserEmail();
    this._checkIfOTPVerified();
    this._commonChangeDetectorRef();
  }

  public otpTypeExpand(type: OTPVerificationModes): void {
    this.selectedOTPType = type;
    switch (type) {
      case 'sms': {
        this._getUserPhoneData();
        break;
      }
      case 'email': {
        this.requestEmailOTP();
      }
    }
  }

  public emailOTPkeyUpEvent(event: any, index: number): void {
    let pos = index;
    if (event.keyCode === 8 && event.which === 8) {
      pos = index - 1;
    } else {
      pos = index + 1;
    }
    if (pos > -1 && pos < this.emailFormInput.length) {
      this.rows._results[pos].nativeElement.focus();
    }
  }

  public phoneOTPkeyUpEvent(event: any, index: number): void {
    let pos = index;
    if (event.keyCode === 8 && event.which === 8) {
      pos = index - 1;
    } else {
      pos = index + 1;
    }
    if (pos > -1 && pos < this.phoneFormInput.length) {
      this.rows._results[pos].nativeElement.focus();
    }
  }

  private _getUserEmail(): void {
    this.userEmail = user.email;
  }

  private _resolveOPTInScope(): void {
    this._commonPatchFormValue('phoneNumber', this.userPhoneNumber);
    this._commonPatchFormValue('phoneCountryCode', this.userCountryCode);
    this._commonChangeDetectorRef();
  }

  ngAfterViewInit(): void {
    this._createOTPFieldTemplateRefs();
    this._listenForOTPInputFieldChange();
  }

  ngOnDestroy(): void {
    this._onDestroy$.next(true);
    this._onDestroy$.complete();
  }

  redirectToUnaccessibleEmailForm(): void {
    const userId = user.id;
    const tallyUrl = `https://tally.so/r/nPA2pd?merchant_id=${userId}&email=${this.userEmail}`;
    window.open(tallyUrl, '_blank');
  }

  public resolveResponseTemplate(status: 'error' | 'success'): TemplateRef<any> {
    switch (status) {
      case 'error':
        return this._errorResponseTemplate;
      case 'success':
        return this._successResponseTemplate;
    }
  }

  public toggleEditPhoneNumber(): void {
    this.showEditPhoneNumber = !this.showEditPhoneNumber;
    this._commonPatchFormValue('phoneNumber', '');
    this._commonPatchFormValue('phoneCountryCode', '');
  }

  public currentUserPhoneNumberSelection($event: MetaPhoneNumberOutput): void {
    this.userPhoneNumber = $event.phoneNumber;
    this.userCountryCode = $event.country.isoCode3;
    this.phonePrefix = this._getPhonePrefix(this.userCountryCode);
  }

  public confirmPhoneNumberSelection(): void {
    this.showEditPhoneNumber = false;
    this.responseMessage = undefined;
    this._commonPatchFormValue('phoneNumber', this.userPhoneNumber);
    this._commonPatchFormValue('phoneCountryCode', this.userCountryCode);
    this._clearOTPFields();
    this._commonChangeDetectorRef();
    this._clearOTPExpiryIntervalAndTimeout();
    this.requestOTP();
    this._logMixpanelEventUseCase.execute({
      eventName: 'otp_edit_phone',
      payload: {
        Status: 'edit phoneNumber OTP',
        phone: this.userPhoneNumber,
        scope: this.scope,
      },
    });
  }

  public requestOTP(): void {
    if (!this.phonePrefix) {
      return;
    }

    this.otpVerificationOptions[1].isSent = true;

    const phoneData: PhoneDataModel = {
      phoneNumber: this.userPhoneNumber.toString(),
      callingCode: this.phonePrefix.toString(),
    };

    this._requestOTPUseCase
      .execute(phoneData)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe({
        next: (res) => {
          this.otpCheckCode = res.checkCode;
          this._expiryTimeInMilliSeconds = +res.codeExpiryInMinutes! * 60000;

          const phoneOTPexpireTime = Date.now() + this._expiryTimeInMilliSeconds;

          cache.set(phoneOtpResendExpireTimeCacheKey, phoneOTPexpireTime);
          cache.set(phoneOTPCheckCodeCacheKey, res.checkCode);

          this._initializeCounter();
          this._trackOTPEvents('sms_otp_requested', { phoneData, response: res });
        },
        error: (err) => {
          this.showOTPCodeTimer = false;
          this.responseMessage = {
            msg:
              err.status === 500
                ? 'رقم الهاتف غير صحيح'
                : AUTH_CONSTS.REGISTER_ERRORS_MAP.get(err.error?.errorCode) || 'حدث خطأ ما',
            status: 'error',
            iconMeta: {
              icon: 'assets/img/auth/danger-white.svg',
              position: 'before',
            },
          };
          this._trackOTPEvents('sms_otp_request_error', { phoneData, error: err });
          this._commonChangeDetectorRef();
        },
      });
  }

  public submitOTP(): void {
    this.responseMessage = undefined;
    if (!this.otpCheckCode) {
      return;
    }

    const OTPPayload: OTPModel = {
      checkCode: this.otpCheckCode,
      otpPasscode: `${this.OTPFormGroup.value.input1}${this.OTPFormGroup.value.input2}${this.OTPFormGroup.value.input3}${this.OTPFormGroup.value.input4}`,
    };

    const correctOTP$: Subject<boolean> = new Subject();

    this._verifyPhoneNumberUseCase
      .execute(OTPPayload)
      .pipe(takeUntil(correctOTP$))
      .subscribe({
        next: (res) => {
          const payload = this.userPhoneNumber
            ? { phone_number: this.userPhoneNumber, phone_prefix: this.phonePrefix }
            : {};

          this._trackOTPEvents('sms_otp_verified_successfully', payload);

          cache.remove(phoneOtpResendExpireTimeCacheKey);
          cache.remove(phoneOTPCheckCodeCacheKey);

          this._setRegisterTokenUseCase.execute(res.token);
          correctOTP$.next(true);
          correctOTP$.complete();
          if (this.showEmailOTPonWithdrawal) {
            this._checkIfOTPVerified();
            this._checkIfVerificationComplete();
          } else {
            this.goToNextStep$.next('optInSuccessVerifyingOTP');
          }
        },
        error: (err) => {
          this._commonOTPVerifyError(err, 'signup_otp_submit_error', OTPPayload);
        },
      });
  }

  public emailOTPFieldSubmit(): void {
    this.responseMessage = undefined;
    const otpFormObj = this.emailOTPForm.value;
    const OTPVal = Object.values(otpFormObj).join('');

    const params: EmailOTPVerifyModel = {
      otpCheckCode: this.otpEmailRequestObject.checkCode,
      otpPassCode: OTPVal,
    };

    this._verifyEmailOTPUseCase.execute(params).subscribe({
      next: (response) => {
        const payload = {
          email: this.userEmail,
          response,
        };
        this._trackOTPEvents('email_otp_verified_successfully', payload);
        this._setRegisterTokenUseCase.execute(response.token);
        this._checkIfOTPVerified();
        this._checkIfVerificationComplete();
        // current flow is to first verify email, if and only if email is verified then verify phone number
        this.otpTypeExpand('sms');

        this.otpVerificationOptions[0].isVerified = true;

        cache.remove(emailOtpResendExpireTimeCacheKey);
        cache.remove(emailOtpRequestObjectCacheKey);
        if (this._EmailOTPExpiryIntervalRef) {
          clearInterval(this._EmailOTPExpiryIntervalRef);
        }
      },
      error: (err) => {
        this._commonOTPVerifyError(err, 'signup_email_otp_submit_error', params);
      },
    });
  }

  public confirmSkipOTP(): void {
    const dialogRef = this._matDialog.open(ConfirmSkipOtpDialogComponent, {
      width: '550px',
    });

    dialogRef.afterClosed().subscribe({
      next: async (res) => {
        if (res && res.skipOTP) {
          this._localStorageService.setStorage(SKIP_EMAIL_OTP, true);
          window.location.reload();
        }
      },
    });
  }

  private _checkIfVerificationComplete(): void {
    const shouldAllowToProceed = this.isUserLoggingIn
      ? this.otpVerificationOptions.filter((x) => x.value === 'email')[0].isVerified
      : this.otpVerificationOptions?.every((x) => x.isVerified === true);
    if (shouldAllowToProceed) {
      this._logMixpanelEventUseCase.execute({
        eventName: 'user_verification_complete',
        payload: { scope: this.scope },
      });
      this.goToNextStep$.next('optInSuccessVerifyingOTP');
      if (this.isUserLoggingIn) {
        navigateTo(PRODUCTS_V2_URL);
      }
      this._commonChangeDetectorRef();
    }
  }

  private _checkIfOTPVerified(): void {
    this.selectedOTPType = null;
    if (user.isEmailVerified) {
      const emailVerificationOption = this.otpVerificationOptions.filter(
        (x) => x.value === 'email',
      );

      emailVerificationOption[0].isVerified = true;
    }

    if (user.isPhoneNumberVerified) {
      const smsVerificationOption = this.otpVerificationOptions.filter((x) => x.value === 'sms');
      smsVerificationOption[0].isVerified = true;
    }

    this._checkIfVerificationComplete();
    this._commonChangeDetectorRef();
  }

  private _commonOTPVerifyError(err: any, eventName: string, request?: any): void {
    this.responseMessage = {
      msg:
        AUTH_CONSTS.REGISTER_ERRORS_MAP.get(err.error.errorCode) ||
        'حدث خطأ ما، من فضلك أعد المحاولة',
      status: 'error',
      iconMeta: {
        icon: 'assets/img/auth/danger-white.svg',
        position: 'before',
      },
    };
    const payload = {
      'error-message': err.error.errorCode,
      'error-object': err,
      'request-payload': request,
    };
    this._trackOTPEvents(eventName, payload);
  }

  public onOTPFieldChange($event: KeyboardEvent, targetControl: string): void {
    if ($event.code === 'Backspace') {
      this.OTPFormGroup.get(targetControl)!.reset();
    } else {
      const currentInputValue = this.OTPFormGroup.get(targetControl)!.value?.toString();
      const newInputValue = parseInt($event.key, 10);
      if (newInputValue && newInputValue !== currentInputValue) {
        $event.preventDefault();
        $event.stopPropagation();
        $event.stopImmediatePropagation();
        this.OTPFormGroup.get(targetControl)!.patchValue(newInputValue);
      }
    }
  }

  private _createOTPFieldTemplateRefs(): void {
    this._commonChangeDetectorRef();
  }

  private _getUserPhoneData(): void {
    this.userPhoneNumber = user.phoneNumber;
    this.phonePrefix = user.callingCode;

    this.userCountryCode = this._getCountryCode(this.phonePrefix);
    this._commonPatchFormValue('phoneNumber', this.userPhoneNumber);
    this._commonPatchFormValue('phoneCountryCode', this.userCountryCode);
    this._commonChangeDetectorRef();

    const phoneOtpExpireTime = cache.get(phoneOtpResendExpireTimeCacheKey);

    if (phoneOtpExpireTime) {
      // remove it from cache if it has expired
      if (phoneOtpExpireTime > Date.now()) {
        this._expiryTimeInMilliSeconds = phoneOtpExpireTime - Date.now();
        this._initializeCounter();
      }
    }
  }

  private _getPhonePrefix(countryCode: string): string {
    return getSupportedPhoneCountriesUseCase(true)
      .find((country: any) => country.isoCode3 === countryCode)
      ?.phoneNumPrefix?.toString();
  }

  private _getCountryCode(phonePrefix: string): string {
    return getSupportedPhoneCountriesUseCase(true).find(
      (country: any) => country.phoneNumPrefix.toString() === phonePrefix,
    ).isoCode3;
  }

  private _optionallyPatchFormFields(OTP: string): void {
    const formValue = this.OTPFormGroup.value;
    Object.keys(formValue)
      .filter((formField) => this.phoneFormInput.indexOf(formField) > -1)
      .forEach((formControlKey, index) =>
        this._commonPatchFormValue(formControlKey, OTP.charAt(index)),
      );
  }

  private _listenForOTPInputFieldChange(): void {
    this.phoneFormInput.forEach((fieldName, index) => {
      this.OTPFormGroup.get(fieldName)!
        .valueChanges.pipe(takeUntil(this._onDestroy$))
        .subscribe({
          next: (_) => {
            const nextField = this.phoneFormInput[index - 1];
            if (nextField) {
              this._tabbableOTPFields[nextField]?.nativeElement.focus();
            } else {
              this._tabbableOTPFields[this.phoneFormInput[index]]?.nativeElement.blur();
            }
          },
        });
    });
  }

  private _commonPatchFormValue(control: string, value: any): void {
    this.OTPFormGroup.patchValue({ [control]: value });
  }

  private _initializeCounter(): void {
    this._clearOTPExpiryIntervalAndTimeout();

    this.showOTPCodeTimer = true;

    this.currentTimeToExpiryInMilliSeconds = this._expiryTimeInMilliSeconds;

    const timeReductionInMilliSeconds = 1000;

    this._OTPExpiryIntervalRef = setInterval(() => {
      this.currentTimeToExpiryInMilliSeconds -= timeReductionInMilliSeconds;

      if (this.currentTimeToExpiryInMilliSeconds <= 0) {
        clearInterval(this._OTPExpiryIntervalRef);
        cache.remove(phoneOtpResendExpireTimeCacheKey);
        return;
      }

      const phoneOTPexpireTime = cache.get(phoneOtpResendExpireTimeCacheKey);

      cache.set(phoneOtpResendExpireTimeCacheKey, phoneOTPexpireTime - timeReductionInMilliSeconds);

      this._commonChangeDetectorRef();
    }, timeReductionInMilliSeconds);

    this._OTPSetTimeoutWrapper = setTimeout(() => {
      this._clearOTPExpiryIntervalAndTimeout();
      this.showOTPCodeTimer = false;
      this._clearOTPFields();
      this._commonChangeDetectorRef();
      this._blurAllOTPFields();
    }, this._expiryTimeInMilliSeconds);
  }

  private _clearOTPExpiryIntervalAndTimeout(): void {
    if (this._OTPExpiryIntervalRef) {
      clearInterval(this._OTPExpiryIntervalRef);
    }
    if (this._OTPSetTimeoutWrapper) {
      clearTimeout(this._OTPSetTimeoutWrapper);
    }
  }

  private _blurAllOTPFields(): void {
    for (const field in this._tabbableOTPFields) {
      if (field in this._tabbableOTPFields) {
        this._tabbableOTPFields[field]?.nativeElement.blur();
      }
    }
  }

  private _commonChangeDetectorRef(): void {
    this._changeDetectorRef.detectChanges();
  }

  private _clearOTPFields(): void {
    this.phoneFormInput.forEach((formFieldName) => this._commonPatchFormValue(formFieldName, ''));
  }
}
